import React from 'react';
import {InfoNotification} from './Info';
import {SuccessNotification} from './Success';
import {WarningNotification} from './Warning';


const Notification = ({ type, message, buttonName, onClick,style }) => {

    if (type === 'success') {
        return (
            <SuccessNotification message={message} buttonName={buttonName} onClick={onClick} />
        )
    } else if (type === 'info') {
        return (
            <InfoNotification message={message} buttonName={buttonName} onClick={onClick} style={style} />
        )
    }

    return (
        <WarningNotification message={message} buttonName={buttonName} onClick={onClick} />
    )
}

export { Notification };
