
import React, { useEffect, useState } from "react";
import { get } from "../../../../../../../Model/Network/Config/Axios";
import { HalfModal } from "../../../../../../Common/fModal/HalfModal";
import { Spinner } from "../../../../../../Common/fLoader";
import TabSwtich from "../../../../../../Common/fTabSwitch";
import Timeline from "./Timeline";
import Details from "./Details";
import Insights from "./Insights";
const GrnView = (props) => {

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [details2,setDetails2] = useState({})
  const [getTab, setTab] = useState(1);


const  getId = () => {
    return props.id;
}

const getGrnDetails =  () => {
    get(`vendor/po/requests/${getId()}/grn/${props.selectedGrnId}/details`, (e, r) => {
        if (r) { 
              setDetails2(r);
        }
    })
}
  const getDetails = () => {
    get(`vendor/procurement/grn/${props.selectedGrnId}/details `, (e, r) => {
      if (r) {
        setDetails(r);
        const updatedItems = r.items.map((i) => {
          const data = props.data.procured.find((e) => e.id === i.id);
          const updatedItem = { ...i };
          updatedItem.poQty = (data && data.qtty) || 0;
          updatedItem.address = (data && data.officeAddress) || i.address || '-';
          updatedItem.officeName = (data && data.officeName) || 0;
          updatedItem.price = (data && data.price) || 0;
          return updatedItem;
        });
        setDetails({ ...r, items: updatedItems });
        setLoading(false);
      }
    })
  }
  useEffect(() => {
    getDetails();
    getGrnDetails()
}, [props.selectedGrnId]);


let tabs = [
    {
        name: 'Details', active: getTab === 1 , onClick: () => {
            setTab(1)
        }
    },
    {
        name: 'Insights', active: getTab === 2 , onClick: () => {
            setTab(2)
        }
    },
    {
        name: 'Timeline', active: getTab === 3 , onClick: () => {
            setTab(3)
        }
    }
    
]
  console.log('props', props.id)
  return (
    <HalfModal title={details && details.attrs && details.attrs.reference} desc="Details of selected grn appears below." close={props.onClose}
    container={{
      left: "30%"
    }}>
      {loading ? (
        <Spinner />
      ) : (
        <div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
        <div
            style={{
              textTransform: "uppercase",
              fontSize: "22px",
              color: "var(--heading-color)",
              fontWeight: "bold",
            }}
          >
        </div>
        <TabSwtich tabs={tabs}  style={{marginTop:'20px'}}/>

        <div className='FadeRight-Effect'>
            {
                getTab===1?<Details  Details={details} InvoiceId={props.viewGrn.invoiceId} PoId={getId()} viewGrn={props.viewGrn} Details2={details2} onReload={()=>{getDetails();getGrnDetails();props.init()}} />:null
            }
            {
                getTab===2?<Insights Details={details} />:null
            }
            {
                getTab===3?<Timeline timelines={details.timelines} owner={details.owner} />:null
            }
             
        </div>
        </div>

      )}
    </HalfModal>
  );
}

export { GrnView }