
import React, { useEffect, useState } from "react";
import Moment from 'moment';
import { get, post } from "../../../../Model/Network/Config/Axios";
import { Spinner } from "../../../Common/fLoader";
import { FloatInputButton } from "../../../Common/fInputs";
import PhotoTile from "../../../Common/fPhoto";
import { handleError, showToast } from "../../../Common/fToast/ToastCallback";
import ConfirmationModal from "./ConfirmationModal";
import { useSelector } from "react-redux";
import { BarCodeGenerate } from "../../../Common/BarCodeGenerate";
import { HalfModal } from "../../../Common/fModal/HalfModal";
import SyncAsn from "./SyncAsn";



const AsnView = ({id,onClose,onReload}) =>{

    const [loading,setLoading] = useState(true);
    const [details,setDetails] = useState({});
    const [cnf,setCnf] = useState(false)
    const [syncModal,setSyncModal] = useState(false)
    const  tenant = useSelector(state=>state.auth.config.tenant);
  
    useEffect(()=>{
          getDetails(id)
    },[id])


 const getDetails = (id) =>{
    get(`/vendor/procurement/asn/getDetails/${id}`,(e,r)=>{
        if(r) {
            setDetails(r);
            setLoading(false);
        }
    })
 }

	const renderTags = (item, index) => {
		return (
			<div key={`Tag-${index}`} style={{display: 'flex', alignItems: 'center'}}>
				<FloatInputButton style={{width: '100%'}} disabled={true} onSubmit={()=>{}} title={item.key} placeholder={item.value}/>
			</div>
		)
	}

    const onCloseModal = () =>{
      setCnf(false)
    }

   const  onSave = () =>{
        post(`vendor/procurement/asn/acknowledge/${details.id}`,
        {},
        (e, r) => {
          if (r) {
            setCnf(false)
            getDetails(id)
            onReload();
      
            showToast({
              type: "success",
              message: "Approved",
            });
          } else {
            setCnf(false)
            handleError(e);
          }
        }
      );
    }

    const ack = () =>{
        setCnf(true)
    }

    return (
      <HalfModal title={details && details.asnId} desc={details.category} close={onClose}
      container={{
        left: "47%",
        
      }}>
        {loading ? (
          <Spinner />
        ) : (
          <div style={{ flex: 1,marginBottom:'50px' }}>
              {details && details.status === 'USER_ACK' && (tenant && ['BAJAJELECT','bajajelec'].includes(tenant)) &&  (
                <div style={{display:'flex',justifyContent:'flex-end',marginBottom:10}}>
                  <div onClick={() => {
                      setSyncModal(true)
                  }} 
                  style={{
                  boxShadow: 'rgb(1 1 1 / 2%) 4px 5px 3px',
                  background: 'rgb(140, 111, 24)',
                  cursor: 'pointer',
                  borderRadius: 8,
                  width: 200,
                  fontSize: 12,
                  textAlign: 'center',
                  padding: '10px 12px',
                  color: "#fff",
                  marginTop: 20,
                }}>
                  Sync
                </div>
                </div>
              )}
            <div
              style={{
                textTransform: "uppercase",
                fontSize: "22px",
                color: "var(--heading-color)",
                fontWeight: "bold",
              }}
            >
              {details.asnId} / {details.category} / {details.status}
            </div>
            <div
              style={{
                border: "1px solid var(--border-color)",
                padding: "20px",
                borderRadius: "15px",
                marginTop: "30px",
                flex: 1,
                minHeight: 190,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: 14,
                }}
              >
                <div>
                  <b>Details</b> <br />
                  {details.vendorName}
                  <br />
                  Delivery Date :{" "}
                  {Moment(details.deliveryDate).format("DD-MM-YYYY")} <br />
                  Created Date :{" "}
                  {Moment(details.createdAt).format("DD-MM-YYYY")} <br />
                  PO Date : {Moment(details.poDate).format("DD-MM-YYYY")} <br />
                  PO Number : {
                    details.poNos.map((item,index)=>{
                      return <span key={index}>{item},</span>
                    })
                  }
                 <br />
                    Challan Number : {details.challanNo} <br />
                    Vehicle Number : {details.vehicleNo} <br />
                    {details.refNo && `Reference Number: ${details.refNo}`}<br />
                    {details.invoices && details.invoices.length > 0 && (
                      <div>
                        Invoice Number: {details.invoices.map((e) => e.toString())}
                      </div>
                    )}
                     Vendor Code : {details.vendorCode} <br />
                     SAP GRN Number : {details.grnNo||'NA'} <br />
                     Vendor bill number : {details.billNumber} <br />
                     Vendor bill Date : {Moment(details.billDate).format('DD-MM-YYYY')} <br />
                    {details && details.eWayBillNo && (
                      <div>
                        E-Way Bill No: {details.eWayBillNo} <br />
                      </div>
                    )}
                    {details && details.eWayBillValidUpto && (
                      <div>
                        E-Way Bill Valid Date : {Moment(details.eWayBillValidUpto).format('DD-MM-YYYY')} <br />
                      </div>
                    )}

                </div>
                  <div style={{ textAlign: "left", paddingLeft: 20 }}>
                    {tenant && tenant === 'TRDT' &&
                      <div style={{ marginBottom: 40 }}>
                        <BarCodeGenerate value={details.asnId} />
                      </div>
                    }
                  <b> Address</b>
                  <br />
                  {details.officeName}
                  <br />
                  Address : {details.officeAddress}
                  <br />
                  Registered Name : {details.officeRegisteredName}
                  <br />
                </div>
              </div>
            </div>

            <div
              style={{
                fontWeight: "600",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              As Per Purchase Order
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#666",
                background: "#f2f2f2",
              }}
            >
              <div style={{ flex: 3, fontSize: 13, padding: 12 }}>Details</div>

              <div style={{ flex: 1, fontSize: 13, padding: 12 }}>Qtty</div>
              <div style={{ flex: 1, fontSize: 13, padding: 12 }}>GRN Qty</div>
              <div style={{ flex: 1, fontSize: 13, padding: 12 }}>
                Invoice Qty
              </div>
            </div>

            {details.items.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    border: "1px solid var(--border-color)",
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      padding: 12,
                      borderRight: "1px solid var(--border-color)",
                    }}
                  >
                    {item.name}<br />
                   <div style={{fontSize:11}}> PO Number : {item.poNo} </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 14,
                      padding: 12,
                      borderRight: "1px solid var(--border-color)",
                    }}
                  >
                    {item.qty}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 14,
                      padding: 12,
                      borderRight: "1px solid var(--border-color)",
                    }}
                  >
                    {item.grnQty}
                  </div>

                  <div
                    style={{
                      flex: 1,
                      fontSize: 14,
                      padding: 12,
                      borderRight: "1px solid var(--border-color)",
                    }}
                  >
                    {item.invoiceQty}
                  </div>
                </div>
              );
            })}

            <div id="Invoice_Insight" className="FadeRight-Effect">
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "30px 0px 10px 0px",
                }}
              >
                Insights
              </div>
              {details.data.length ? details.data.map(renderTags) : <></>}
            </div>

            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                margin: "30px 0px 15px px",
              }}
            >
              Attachment
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "-15px",
                flexWrap: "wrap",
                maxHeight: "250px",
                overflowY: "scroll",
              }}
            >
              {details.files.length > 0 ? (
                details.files.map((item, index) => {
                  return <PhotoTile key={`Photo-${index}`} link={item.path} />;
                })
              ) : (
                <></>
              )}
            </div>
            {details.status === "USER_ACK" ? (
              <div
                style={{
                  // left: "calc(100% - 640px)",
                  width:'53%',
                  right: 0,
                  bottom: 0,
                  background: "#ffffff",
                  borderTop: "1px solid var(--border-color)",
                  position: "fixed",
                  height: 65,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={ack}
                  className="btn btn-portlet-success"
                  style={{
                    background: "#E4EFE7",
                    color: "#198754",
                    width: "100%",
                    maxWidth:'100%',
                    margin:'30px'
                  }}
                >
                  Acknowledge
                </div>
              </div>
            ) : null}
            {cnf ? <ConfirmationModal onClose={onCloseModal} onSave={onSave}/> : null}
            {syncModal ? <SyncAsn onClose={() => setSyncModal(false)} item={details} setLoading={() =>  getDetails(id)} /> : null}
          </div>
      
        )}
      </HalfModal>
    );
}

export {AsnView}