import React from 'react';


class Index extends React.Component {


    render() {
        let { address } = this.props;
        return (
            <div style={{
                padding: 10
            }}>
                <p style={{
                    fontWeight: 600,
                    marginBottom: 5
                }}>{this.props.title}</p>

                <p>
                    {address.registered_name ? address.registered_name : '-'}
                </p>
                <p>
                    {address.address ? address.address : '-'}
                </p>
                <p>
                    {address.city ? address.city : '-'}
                </p>
                <p>
                    GSTIN: {address.gstin ? address.gstin : '-'}
                </p>
                <p>
                    PAN: {address.pan ? address.pan : '-'}
                </p>
               


            </div>
        )
    }
}

Index.defaultProps = {
    title:"Ship to"
}

export default Index;