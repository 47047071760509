import React, { useEffect, useState } from 'react';
import axios, { get } from '../../../../../Model/Network/Config/Axios';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec2';
import { Modal } from '../../../../Common/fModal/Modal';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';
import { getBaseUrl } from '../../../../../Model/Network/Config/DecentralizeWrapper';



const Tds = (props) => {


    const [tds,setTds] = useState([]);
    const [changeTds,setChangeTds] = useState(false);
    const [allTds,setAllTds] = useState([]);
    const [selectedTds,setSelectedTds] = useState(false);


    useEffect(()=>{
        get(`/vendor/config/tds/all`,(e,r)=>{
            if (r) {
                setTds(r.tds.map(val=>({
                    id: val.id,
					label: val.code,
					des: val.description,
					tax: val.tax
                })))
			}
        })
    },[])

	const updateTDS = async(dbId, tdsId) =>{

		await axios({
			method: 'PUT',
			url: `${getBaseUrl()}/vendor/vendor/creditNote/${dbId}/update`,
			data: {
				"key":'tds',
				"value":String(tdsId)
			}
		})
			.then(() => {
				showToast({
					type: 'success',
					message: "Field update successfully"
				})
				props.init();
			})
			.catch(e => {
				handleError(e);
			})

		setChangeTds(false);
	}

    return(
        <>      
                <div style={{marginTop:0}}>
                    <p style={{marginBottom:10}}>Tds</p>
                    <div style ={{
                        height:40,
                        background:'var(--off-white)',
                        border:'1px solid var(--border-color)',
                        width:'100%',
                        padding:10,
                        fontSize:12,
                        color:'var(--text-color)'
                    }} onClick={()=>{
                        setChangeTds(true);
                    }}>
                        {props.value?props.value.toFixed(2):props.value}
                    </div>
                </div>
                {
					changeTds ?
						<Modal
							onSave={() => {
								if(props.disabled){
									showToast({
										type:'error',
										message:'TDS Can Only Be Changed For Credit Notes In Approval Stage'
									})
									return ;
								}
								if (!selectedTds) {
									showToast({
										type: 'error',
										message: 'Please select tds'
									})
									return;
								}

								updateTDS(props.dbId,selectedTds.id)
							}}
							style={{
								save: {
									padding: '12px 15px',
									width: '100%',
									textAlign: 'center',

								}
							}}
							show={true}
							title="Change Tds"
							des="Change Tds"
							close={() => {
                                setChangeTds(false);
							}}
							buttonName="Change Tds "
						>

							<SearchableSelect
								disabled={props.disabled}
								label="Select Tds"
								placeholder="Select Tds"
								onChange={val => {
									if (val.length > 0) {
										let allItems = tds.filter(item => {
											if (val.toLowerCase().includes(item.label)) {
												return true
											}
											if (val.toLowerCase().includes(item.desc)) {
												return true;
											}
											if (val.toLowerCase().includes(item.tax)) {
												return true
											}
											return false;
										})

                                        setAllTds(allItems);
									} else {
                                        setTds(tds);
									}
								}}
								select={selectedTds => {
                                    setSelectedTds(selectedTds);
								}}
								value={selectedTds}
								items={allTds.length > 0 ? allTds : tds}
							/>
						</Modal>
						: null
				}

        </>
    )
}

export default Tds;