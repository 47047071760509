import React, { useEffect, useRef, useState } from "react"
import PhotoTile from "../../../../../../Common/fPhoto"
import {Modal} from "../../../../../../Common/fModal/Modal"
import Empty from "../../../../../../Common/fCard/Empty"
import moment from "moment"
import { PDFExport } from '@progress/kendo-react-pdf';
import { FunctionalInput } from "../../../../../../Common/fInputs"
import { get, post } from "../../../../../../../Model/Network/Config/Axios"
import { showToast } from "../../../../../../Common/fToast/ToastCallback"
import { numberWithCommas } from "../../../../../../../Utils/NumberToComma"
import { useSelector } from "react-redux"
import { Select } from "../../../../../../Common/Class/Select"
// import { post } from "../../../../../../../Model/Network/Config/Axios";
// import { showToast } from "../../../../../../Common/fToast/ToastCallback";

export default ({ Details = {}, viewGrn = {},PoId, Details2 = {},onReload,InvoiceId }) => {
    const [approveModal,setApproveModal] = useState(false);
    const [declineModal,setDeclineModal] = useState(false);
    const [revert,setRevert] = useState(false)
    const PDF = useRef(), REMARKS = useRef();
    const {isSuper,tenant}= useSelector(state=>state.auth.config);
    const [items,setItems] = useState([]);
    const [value,setValue] = useState(undefined);


    let total = Details.items.reduce((a,el) => {
        let price = Number(el.price);
        let qtty = Number(el.qtty);
        return a+(price*qtty);
    },0)

    useEffect(()=>{
        get(`/vendor/config`,(e,r)=>{
			if(r) {
				let id = null
				r.items.forEach(val=>{
					if(val.name === Details.attrs['po.service']) {
						id=val.id
					}
				})
				get(`vendor/flow/${id}`,(e,r)=>{
					if(r) {
                        console.log('r', r)
						let list = [];
						let singleItem = r.flow.nodes;
					    Object.keys(singleItem).forEach(val=>{
							if((!singleItem[val].type.includes('start-vendor') && singleItem[val].type!=='logic' && singleItem[val].type!=='integration' && singleItem[val].type !=='statement' && singleItem[val].type !== 'close') && singleItem[val].data) {
								list.push({
									value:val,
									label:`${singleItem[val].data.title}    ::x-axis:${singleItem[val].position.x}::y-axis:${singleItem[val].position.y}`,
                                    des:`ID ${singleItem[val].data.id} (${singleItem[val].data.code|| 'NA'})`
								})
							}
						})
                        setItems(list);
					}
				})
			}
		})
    },[Details])

    return (
        <>
          <div style={{display:'flex',justifyContent:'flex-end',alignItems:'space-between',marginRight:20,marginTop:10}}>
          <div onClick={() => { PDF.current.save() }} style={{ width: 130, textAlign: 'center'}} className='btn btn-portlet'>
                Download Pdf
            </div>
            {(!InvoiceId) && (isSuper) && ( tenant === 'DTDC') &&
            <div style={{ width: 100, textAlign: 'center',marginLeft:'5px'}} className='btn btn-portlet' onClick={()=>{setRevert(true)}}>
                Revert
            </div>}

          </div>
            <PDFExport margin={20} fileName={Details.attrs.reference} ref={PDF}>
                <div>
                      {/* <div className='btn btn-danger' style={{width:130,textAlign:'center',marginLeft:15}} onClick={()=>{
                            post(`vendor/procurement/grn/${Details2.id}/withdraw`,{force:true,remarks:'wrong qtty'},(e,r)=>{
                                if(r) {
                                    showToast({
                                        type:'success',
                                        message:"GRN withdraw successfully"
                                    })
                                } else {
                                    showToast({
                                        type:'error',
                                        message:e.data.message
                                    })
                                }
                            })
                         }}>
                            Withdraw
                        </div> */}
                    <div style={{ fontSize: '14px', marginTop: '40px', fontWeight: 'bold' }}>GRN Items</div>
                    <table style={{ border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px', width: '100%' }}>
                        <tr style={{ background: 'var(--lite-color)', textAlign: 'left' }}>
                            <th style={{ padding: '10px' }}>Name</th>
                            <th style={{ padding: '10px' }}>Delivery Address</th>
                            <th style={{ padding: '10px' }}>Order quantity</th>
                            <th style={{ padding: '10px' }}>Invoice quantity</th>
                            <th style={{ padding: '10px' }}>Qty</th>
                            <th style={{ padding: '10px' }}>Price</th>
                            <th style={{ padding: '10px' }}>GRN Amt</th>
                        </tr>
                        {Details && Details.items.length ?
                            Details.items.map(row => {
                                return (
                                    <tr>
                                        <td style={{ padding: '10px' }}>
                                            {row.name} <br />
                                            Purchase Order Id : {row.orderId} <br />
                                            Item Ref No : {row.id}
                                            {
                                                row.attrs && Object.keys(row.attrs).length > 0 &&
                                                    row.attrs["line_item_ref_no"] ?
                                                    <p>Line Item Reference No : {row.attrs["line_item_ref_no"]}</p> : <></>
                                            }
                                            {
                                                row.attrs && Object.keys(row.attrs).length > 0 &&
                                                    row.attrs["so_no"] ?
                                                    <p>SO Number : {row.attrs["so_no"]}</p> : <></>
                                            }
                                        </td>
                                        <td style={{ padding: '10px' }}>{row.address}</td>
                                        <td style={{ padding: '10px' }}>{row.poQty}</td>
                                        <td style={{ padding: '10px' }}>{row.invoiceQty}</td>
                                        <td style={{ padding: '10px' }}>{row.qtty}</td>
                                        <td style={{ padding: '10px' }}>{row.price}</td>
                                        <td style={{ padding: '10px' }}>{(Number(row.price)*Number(row.qtty)).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ padding: '10px', textAlign: 'center' }} colSpan={3}>No Items</td>
                            </tr>
                        }
                    </table>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <div style={{ fontWeight: 'bold' }}>
                            Total GRN Amount
                        </div>
                        <div style={{ marginRight: '20px' }}>
                            {numberWithCommas(total)}
                        </div>
                    </div>
                    <hr />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        height: 45,

                    }} className='flex flex-v-center'>
                        <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13 }}>
                            Delivery Date:  <span>{moment(viewGrn.date).format("DD-MM-YYYY")}</span>
                        </p>
                    </div>
                    <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13,marginBottom :10 }}>
                        GRN ID: {Details2.reference ? Details2.reference : "-"}
                    </p>
                    <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13 }}>
                        GRN Owner: {Details.owner ? Details.owner.ownerName : "-"}
                    </p>
                    <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13, marginTop: 10 }}>
                        Remarks: {Details2.remarks}
                    </p>
                    <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 13 }}>All Attachment</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                        {
                            Details.documents && Details.documents.map((item, index) => {
                                return (
                                    <PhotoTile key={index} link={item} />
                                )
                            })
                        }
                        {
                            Details.documents && Details.documents.length === 0 ?
                                <Empty />
                                : null
                        }
                    </div>
                </div>
            </PDFExport>
            {Details.status === "APPROVAL" && 
            <div style={{display:'flex',gap:10}}>
            <div className='btn btn-portlet-sucess' onClick={()=>setApproveModal(true)} style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'620px'}}>Approve</div>
            <div className='btn btn-portlet-sucess' onClick={()=>setDeclineModal(true)} style={{ background: "#ffdad4", color: '#ba1b1b', 'min-width': '50%' }}>Decline</div>
            </div>}
            {
                (approveModal || declineModal) &&
                <Modal show={true}
                    des=""
                    title='Remarks'
                    style={{
                        save: {
                            background: "var(--primary-color)",
                            border: "2px solid var(--primary-color)",
                            padding: '8px 15px',
                            width: '100%',
                            textAlign: 'center'
                        }
                    }}
                    close={() => {
                        setApproveModal(false);
                        setDeclineModal(false);
                    }}
                    onSave={() => {
                        let remarks = REMARKS.current.value;
                        if (!remarks) {
							showToast({
								type: 'error',
								message: 'Please enter the remarks'
							})
							return
						}
                        post(approveModal?`vendor/procurement/grn/${Details2.id}/approve`:`vendor/procurement/grn/${Details2.id}/decline`,{remarks},(e,r)=>{
                            if(r){
                                if(approveModal)
                                showToast({
                                    type: 'success',
                                    message: 'GRN Approved sucessfully'
                                })
                                else 
                                showToast({
                                    type: 'success',
                                    message: 'GRN Declined sucessfully'
                                })
                                setApproveModal(false);
                                setDeclineModal(false)
                                onReload()
                            }else{

                            }
                        })
                    }}
                    buttonName={approveModal?'Approve':'Decline'}
                >
                    <div className="light-group" style={{ marginBottom: 20 }}>
                        <FunctionalInput type="text" title="Enter Remarks" ref={REMARKS} />
                    </div>
                </Modal>
            }
            {revert &&
                <Modal 
                    show={true}
                    title="Revert"
                    close={() => {
                        setRevert(false)
                    }}
                    buttonName='Revert'
                    onSave={()=>{
                        let revertedToTaskId = value.value
                        if (!revertedToTaskId) {
							showToast({
								type: 'error',
								message: 'Please select the stage'
							})
							return
						}
                        let data = {
                            "stage":revertedToTaskId,
                            "grn":Details2.id
                        }
                        post(`/vendor/po/requests//vendor-grn/${PoId}/stage.change.grn`,data,(e,r)=>{
                            if(r){
                                showToast({
                                    type: 'success',
                                    message: 'GRN reverted successfully'
                                })
                                onReload()
                                setRevert(false)
                                setValue()
                            }else{
                                showToast({
                                    type:'error',
                                    message:e.message
                                })
                                setRevert(false)
                            }
                        })
                    }}

                >
                    <Select
					 placeholder="Select the stage"
					 items={items}
					 value={value}
					 select={value=>{
                       setValue(value);
					 }}
					/>

                </Modal>
            }
        </>
    )
}