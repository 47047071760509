import React from 'react';


class Index extends React.Component {


    render() {
        let { vendor } = this.props;
        return (
            <div style={{
                padding: 10
            }}>
                <p style={{
                    fontWeight: 600
                }}>Supplier Details</p>

                <p>
                    {vendor.address ? vendor.address.address : '-'}
                </p>

                <p>
                    {vendor.address ? vendor.address.city : '-'}
                </p>

                <p>
                    Vendor Email: {vendor.email ? vendor.email : '-'}
                </p>
                <p>
                    GSTIN: {vendor.gstin ? vendor.gstin : '-'}
                </p>
                <p>
                    PAN: {vendor.pan ? vendor.pan : '-'}
                </p>




            </div>
        )
    }
}

export default Index;