import React, { useState } from 'react';
import {connect} from 'react-redux'
import _ from 'lodash'
import ICONS from './icons'

const Sidebar = (props)=>{

    const KEY = useState(undefined);

    const mapItem = (data) => {
        let config = props.config;
        if (data.module && !_.includes(config.modules, data.module)) {
            return;
        }
        if (data.needSuper && !config.isSuper) {
            return;
        }
        if(data.otherConfigCheck && !config.isSuper && !_.includes(config.modules, data.module)) {
            return;
        }

        if(data.pluginCheck && config.isSuper && !config.plugins[data.plugin]) {
            return ;
        }

        if(data.pluginCheck && !config.isSuper && !config.plugins[data.plugin]) {
            return ;
        }
        
        return (
            <div style={{padding: '10px 15px', margin: '4px 8px',}} className={`item normal ${data.active ? "active" : null}`} onClick={() => {
                KEY[1](undefined)
                if (data.link) {
                    props.route.push(data.link)
                } else {
                    if (data.onClick)
                        data.onClick()
                }
            }} key={data.value}>
                <div>
                    <div style={{ position: 'relative', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {data.active?<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fill: '#fff'}}>{ICONS[data.cname]}</div>:
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fill: 'var(--light-color)'}}>{ICONS[data.cname]}</div>}
                    </div>
                </div>
                <div className="word-content">
                    <h5>{data.value}</h5>
                </div>
            </div>
        )
    }


    const { common } = props;
    if(common.length===0)
        return (<div/>);
    return (
        <div className="sidebar-container">
            <p className="titles">{props.text}</p>
            <div className="csidebar">
                <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: 1,
                    top: 0,
                    width: 12
                }}>
                    <span className="titles-vertical">{props.text}</span>
                </div>
                <div className="sideArea">
                    {
                        common.map(mapItem)
                    }
                </div>
            </div>
        </div>
    )
}

const Csidebar = connect(state => ({
    config: state.auth.config
}))(Sidebar)

export default Csidebar
