import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import Moment from 'moment';
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import SearchVendor from "../../../../Common/fSubmitInvoice/common/SearchVendorNormal";
import {SearchOffice2} from "../../../../Common/fSubmitInvoice/common/SearchOffice";
import {FileUpload} from "../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../Common/fPhoto";
import {FunctionalInput} from "../../../../Common/fInputs";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {post} from "../../../../../Model/Network/Config/Axios";
import {Form} from '../../../../Common/Class/Form/index';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';
import { Notification } from '../../../../Common/Components/Workflow/CommonNotification';

export default function Render(props) {
	let {data} = props;
	if (!data)
		data = {}
	const [inputData, setInputData] = useState({
		vendor: data.vendor,
		billingTo: data.billingTo,
		shippingTo: data.shipTo,
		billDate: undefined,
		due: undefined,
		billNumber: undefined,
	});
	const [files, setFiles] = useState([]);
	const {roundOff} = useSelector(state=>state.auth.config.plugins)
	let Base = useRef(), Cgst = useRef(), Igst = useRef(), Sgst = useRef(), Total = useRef(),FORM=useRef(),Cess=useRef(),
		DISCOUNTAMOUNT=useRef(),ROUNDOFF=useRef();


	useEffect(()=>{
		//vendor::${this.getId()}
		if(props.poId) {
			FORM.current.loadForm(`vendor::category::${props.categoryId}`)

		} else {
			FORM.current.loadForm(`vendor::${props.categoryId}`)
		}
	},[])
	let submit = () => {
		let {billDate, due, billNumber, vendor, shippingTo, billingTo} = inputData;
		let base = Base.current.value;
		let igst = Igst.current.value;
		let cgst = Cgst.current.value;
		let sgst = Sgst.current.value;
		let total = Total.current.value;
		let cessAmount = 0;

		let discount=0,round=0,discountPercentage=0;

		if(props.cess) {
			cessAmount = Cess.current.value;
		}
		if (!billNumber) {
			showToast({
				type: 'error',
				message: "Please enter the bill Numbeer"
			})
			return;
		}

		if (!billDate) {
			showToast({
				type: 'error',
				message: "Please enter the bill date"
			})
			return;
		}
		if (!base) {
			showToast({
				type: 'error',
				message: 'Please enter the base amount'
			})
			return;
		}

		if (!igst) {
			showToast({
				type: 'error',
				message: 'Please enter the igst amount'
			})
			return;
		}

		if (!cgst) {
			showToast({
				type: 'error',
				message: 'Please enter the cgst amount'
			})
			return;
		}

		if (!sgst) {
			showToast({
				type: 'error',
				message: 'Please enter the sgst amount'
			})
			return;
		}

		if (!total) {
			showToast({
				type: 'error',
				message: 'Please enter the total amount'
			})
			return;
		}

		if (!props.poId) {
			if (!vendor) {
				showToast({
					type: 'error',
					message: "Please select the vendor"
				})
				return;
			}
			if (!shippingTo) {
				showToast({
					type: 'error',
					message: "Please select the ship to address"
				})
				return;
			}

			if (!billingTo) {
				showToast({
					type: 'error',
					message: "Please select the bill to address "
				})
				return;
			}
		}


		if (billDate) {
			billDate = Moment(billDate, 'YYYY-MM-DD');
			billDate = Moment(billDate).valueOf();
		}
		if (due) {
			due = Moment(due, 'YYYY-MM-DD');
			due = Moment(due).valueOf();
		}
		if(!files.length){
			showToast({
				type: 'error',
				message: "Attachment is required"
			})
			return;
		}
		if (props.poId) {
			let data = {
				billDate,
				due,
				billNumber,
				files,
				poId: +props.poId,
				cgst,
				sgst,
				igst,
				total,
				base,
			}
			data.categoryId = props.categoryId;
			if(props.superId) {
				data.superId=props.superId
			}

			FORM.current.getKeyValue(cb=>{
				data.forms=cb;
				post(`vendor/submit/invoice/direct/po`, {data}, (e, r) => {
					if (r) {
						showToast({
							type: 'success',
							message: 'Invoice uploaded successfull'
						})
						props.onClose(false);
						props.init();
					} else {
						handleError(e)
					}
				})
			})

		} else {

			if(roundOff) {
                discount = +DISCOUNTAMOUNT.current.value;
                round = +ROUNDOFF.current.value;
                if(discount) {
                    discount = +(discount.toFixed(2))
                    discountPercentage = +((discount*100)/total).toFixed(2)
                }

                if(round && (round>10 || round < (-10))) {
                    showToast({
                        type:'error',
                        message:"RoundOff amount is not allowed greater than 10 or lesser then -10"
                    })
                    return
                 }  else if(round) {
                    round = +(round.toFixed(2))
                }

            }

			let data = {
				billDate,
				due,
				billNumber,
				billTo: billingTo,
				shipTo: shippingTo,
				vendor,
				cgst,
				sgst,
				igst,
				total:+(Number(total)),
				base,
				files,
				cessAmount,
				addressId: vendor.key,
				discountAmount:discount,
                roundOff:round,
                discount:discountPercentage,
			}
			data.service = props.categoryId;
			if(props.superId) {
				data.superId=props.superId
			}

			FORM.current.getKeyValue(cb=>{
				data.forms=cb;
				post(`vendor/submit/invoice/direct`, {data}, (e, r) => {
					if (r) {
						showToast({
							type: 'success',
							message: 'Invoice uploaded successfull'
						})
						props.onClose(false)
						props.init();
					} else {
						handleError(e)
					}
				})
			})

		}

	}
	return (
		<SidePane button={false} onClose={() => props.onClose(false)}>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
				<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3', marginBottom: 30}}>Submit Invoice
				</div>
				<div id="Invoice_Format" className='FadeRight-Effect'>
					{
						(props.vendor && props.vendor.panStatus) ?
							<Notification style={{ marginBottom: '20px' }} type="info"
								message={<p>{`Pan is ${props.vendor.panStatus} for this vendor`}</p>} />
							: null
					}
					{
						props.poId ?
							<div/> : <div>

								<SearchVendor
									onSelect={(vendor) => {
										setInputData({
											...inputData,
											vendor
										})
									}}
								/>
								<SearchOffice2 
									entityId={(props && props.entityId)?props.entityId:undefined}
									p2p={true} title="Bill To" office={inputData.billingTo} onSelect={(billingTo) => {
									setInputData({
										...inputData,
										billingTo: {
											...billingTo,
											name: billingTo.label
										}
									})
								}}/>
								<SearchOffice2 
									entityId={(props && props.entityId)?props.entityId:undefined}
									p2p={true} office={inputData.shippingTo} title="Ship To" onSelect={(shippingTo) => {
									setInputData({
										...inputData,
										shippingTo: {
											...shippingTo,
											name: shippingTo.label
										}
									})
								}}/>

							</div>
					}
					<div style={{display: 'flex'}}>
						<FunctionalInput type="text" title="Bill Number" ref={() => {
						}} onChange={(e) => {
							let billNumber = e.target.value;
							setInputData({
								...inputData,
								billNumber
							})
						}}/>
					</div>

					<div style={{display: 'flex'}}>
						<div style={{flex:1}}>
							<FunctionalInput type="date"  title="Bill Date" ref={() => {
								}} onChange={(e) => {
									let billDate = e.target.value;
									setInputData({
										...inputData,
										billDate
									})
								}}/>
						</div>
						<div style={{marginLeft: 12,flex:1}}>
							<FunctionalInput  type="date" title="Due Date" ref={() => {
							}} onChange={(e) => {
								let due = e.target.value;
								setInputData({
									...inputData,
									due
								})
							}}/>
						</div>
					</div>


					<div style={{
						display: 'flex',
						flex: 1,
						marginTop: 20
					}}>
						<div style={{flex: 1, marginRight: 20}}>
							<FunctionalInput 
							type="text" 
							title="Base Amount" 
							ref={Base}
							onChange={(e)=>{
								let val = e.target.value;
								Total.current.value = Number(val)+Number(Sgst.current.value)+Number(Cgst.current.value)+Number(Igst.current.value)
								if(val.includes(',')) {
									let value = val.split(',').join('');
									Base.current.value = value
								}
							}}
							/>
						</div>
						<div style={{flex: 1}}>
							<FunctionalInput type="text" title="Igst" ref={Igst}
							onChange={(e)=>{
								let val = e.target.value;
								Total.current.value = Number(val)+Number(Sgst.current.value)+Number(Cgst.current.value)+Number(Base.current.value)
								if(val.includes(',')) {
									let value = val.split(',').join('');
									Igst.current.value = value
								}
							}}
							/>
						</div>
					</div>

					<div style={{
						display: 'flex',
						flex: 1,
						marginTop: 20
					}}>
						<div style={{flex: 1, marginRight: 20}}>
							<FunctionalInput type="text" title="Cgst" ref={Cgst}
							onChange={(e)=>{
								let val = e.target.value;
								Total.current.value = Number(val)+Number(Sgst.current.value)+Number(Igst.current.value)+Number(Base.current.value)
								if(val.includes(',')) {
									let value = val.split(',').join('');
									Cgst.current.value = value
								}
							}}
							/>
						</div>
						<div style={{flex: 1}}>
							<FunctionalInput type="text" title="Sgst" ref={Sgst}
							onChange={(e)=>{
								let val = e.target.value;
								Total.current.value = Number(val)+Number(Cgst.current.value)+Number(Igst.current.value)+Number(Base.current.value)
								if(val.includes(',')) {
									let value = val.split(',').join('');
									Sgst.current.value = value
								}
							}}
							/>
						</div>
					</div>
					{props.cess?
					<div style={{
						display: 'flex',
						flex: 1,
						marginTop: 20
					}}>
						<FunctionalInput type='text' title="Cess Amount" ref={Cess}/>
					</div>
					: null
				}

				  	{
						roundOff ?
						<>
							<FunctionalInput type="number" title="RoundOff Amount" ref={ROUNDOFF} />
							<FunctionalInput type="number" title="Discount Amount" ref={DISCOUNTAMOUNT} />

						</>
						: null
					}

					<div style={{
						display: 'flex',
						flex: 1,
						marginTop: 20
					}}>
						<FunctionalInput type='text' title="Total Amount" ref={Total}/>
					</div>
					<Form handler={ref=>FORM.current=ref} />
					<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment</div>
					<div style={{
						display: 'flex',
						marginLeft: '-15px',
						flexWrap: 'wrap',
						maxHeight: '250px',
						overflowY: 'scroll'
					}}>
					<ReadOnly>
						<FileUpload onUpload={({file}) => {
							setFiles(prev => [...prev, file]);
						}}/>
					</ReadOnly>

						
						{
							files.length > 0 ?
								files.map((item, index) => {
									return (
										<PhotoTile key={`Photo-${index}`} link={item}/>
									)
								}) : <></>
						}
						
					</div>
					<div className="btn btn-portlet" onClick={submit}
						 style={{maxWidth: '100%', height: 40, marginTop: 20}}>Upload
					</div>

				</div>
			</div>
		</SidePane>
	)
}
