import React from 'react'

const index = ({ flags }) => {
    let isFlag = flags.find(el => el.flag);
    if (!isFlag) {
        return <></>
    }
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 25,gap:5 }}>
            {
                flags.map(el => {
                    if (!el.flag) return <></>

                    return (<div style={{ backgroundColor: `${el.color}`, color: 'white', border: `1px solid ${el.color}`, borderRadius: '12px',padding:'6px 10px' }}>
                        {el.name}
                    </div>)
                })
            }
        </div>
    )
}

export default index