import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { get } from '../../../Model/Network/Config/Axios';
import { KeyValue } from '../Components/KeyValue';
import { PieGraph } from '../fAnalytics';
import Container from '../fAnalytics/Container';
import { Modal } from '../fModal/Modal';
import moment from 'moment';
import { DownloadCsv } from '../../../Utils/Downloader';


const Allocation = forwardRef(({type,reference,logs},ref) => {



    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});
    const [showChart,setShowChart] = useState(false);
    const [toggleData] = useState('ratio');
    const [viewLogs,setViewLogs] = useState(false);

    const log=(item)=>{
        return (
            <div>
                <KeyValue title='Log' value={(item && item.log)?item.log:""} />
                <KeyValue title='Owner Name' value={(item && item.owner && item.owner.ownerName)?item.owner.ownerName:""} />
                <KeyValue title='Owner ID' value={(item && item.ownerId)?item.ownerId:""} />
                <KeyValue title='Owner Type' value={(item && item.ownerType)?item.ownerType:""} />
                <KeyValue title='Remarks' value={(item && item.remarks)?item.remarks:""} />
                <KeyValue title='Time' value={(item && item.time)?moment(item.time).format("DD-MM-YYYY"):""} />
            </div>
        )
    }
   

    useImperativeHandle(ref,()=>({
        INIT:()=>INIT()
    }))


    let INIT = () => {
        get(`vendor/costAllocation/getAllocations?referenceType=${type}&referenceId=${reference}`,(e,r)=>{
            if(r) {
                setData(r);
                setLoading(false);
            }
        })
    }


    useEffect(()=>{
        get(`vendor/costAllocation/getAllocations?referenceType=${type}&referenceId=${reference}`,(e,r)=>{
            if(r) {
                setData(r);
                setLoading(false);
            }
        })
    },[reference])

    if(loading) {
        return <></>
    }

    let {allocations,baseType,totalAllocatedAmount}= data.data;
    const pieItems = allocations.map(e => e.location);
    const pieData = allocations.map(e => e[toggleData]);


    return(
        <div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'baseline'}}>
            <div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Cost Allocation</div>
            {
                logs && logs.length>0 ?<div onClick={()=>{setViewLogs(true)}} style={{fontWeight:'bold',cursor:'pointer', marginLeft:'15rem'}}>Show Logs</div>:null
            }
            <div className="flex flex-v-center" style={{
                    backgroundColor: '#767DFF',
                    height: 22,
                    width: 100,
                    borderRadius: 32,
                    cursor: 'pointer',
                    padding: '0px 3px',
                }}>
                    <div className="flex flex-v-center" style={{ flex: 1, height: '100%' }}>
                        <p onClick={()=> setShowChart(false)} className="flex flex-v-center flex-h-center" style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: 11,
                            height: '78%',
                            ...!showChart ? {
                                backgroundColor: '#fff',
                                borderRadius: 32,
                                color: '#767DFF'
                            } : {
                                color: '#fff'
                            }
                        }}>
                            Table
                        </p>
                        <p onClick={() => setShowChart(true)} className="flex flex-v-center flex-h-center" style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: 11,
                            height: '78%',
                            ...showChart ? {
                                backgroundColor: '#fff',
                                borderRadius: 32,
                                color: 'var(--active-background)'
                            } : {
                                color: '#fff'
                            }
                        }}>
                            Chart
                        </p>
                    </div>
                </div>
                </div>
            <KeyValue title='Allocation Type' value={baseType} />
            <KeyValue title='Total Allocation Amount' value={totalAllocatedAmount} />
            {!showChart &&
                <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <p
                    onClick={()=>{
                        let base = [['location','Ratio','Amount']];
                        allocations.forEach(item => {
                            let temp = [item.location,item.ratio.toFixed(2),item.amount.toFixed(3)]
                            base.push(temp);
                        });
                        DownloadCsv("costAllocation.csv",base);
                    }}
                    className="btn btn-portlet"
                    style={{
                        width: '98px',
                        fontSize: '9px',
                        padding: '4px 0px',
                        margin: '0 10px 10px 0',
                    }}
                >
                    {"Download Csv"}
                </p>
                    </div>
            }
            {!showChart && <div>
            <div style={{background:'#F9F9FA',height:30,display:'flex',alignItems:'center'}}>
                {
                [
                    {name:'location',style:{flex:3,fontWeight:'bold'}},
                    {name:'Ratio',style:{flex:1,fontWeight:'bold'}},
                    {name:'Amount',style:{flex:1,fontWeight:'bold'}},
                  
                ].map((item,index)=>{
                    return(
                        <div key={index} style={{display:'flex',border:'1px solid var(--border-color)',borderTopLeftRadius:2,borderTopRightRadius:2,height:30,paddingLeft:5,alignItems:'center',flex:1,...item.style}}>
                            {item.name}
                        </div>
                    )
                })
                }
            </div>

            {
             allocations.map((item,index)=>{
                return(
                    <div key={index} style={{display:'flex',flex:1,minHeight:35,border:'1px solid var(--border-color)'}}>
                        <div style={{minHeight:35,flex:3,padding:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>
                            {item.location} <br></br>
                            {item.budgetId?<>{item.budgetId}</>:'-'}
                        </div>
                        <div style={{minHeight:35,flex:1,padding:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{item.ratio ? item.ratio.toFixed(2):item.ratio} %</div>
                        <div style={{minHeight:35,flex:1,padding:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{item.amount?item.amount.toFixed(3):item.amount}</div>
                    
                    </div>
                )
            })       
            } 
            </div>}  


            {showChart && <Container width="40%" style={{
                    container:{
                        margin:0,
                        marginTop:30
                    }
                }}>
                    <PieGraph
                        textColor="#fff"
                        colors={["#0d6efd","#fd7e14","#20c997","#d63384"]}
                        title={`Cost Allocationasd`}
                        height={350}
                        data={pieData}
                        items={pieItems}
                    />
            </Container> }
            {
                viewLogs?
                    <Modal
                        hideButton={true}
						show={true}
						title="Logs"
						close={() => {
							setViewLogs(false)
						}}
                        modalStyle={{
                            marginRight:'1.5%',

						}}
					>
						{
                            logs.map(log)
                        }
					</Modal>:null
            }


        </div>
    )
})

Allocation.defaultProps = {
    type:'vendor-invoice',
}

export {Allocation}