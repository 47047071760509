
import React,{ forwardRef, useImperativeHandle, useState ,useMemo, useEffect} from 'react';
import JoditEditor from 'jodit-react';

const options = [ 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];

export default  forwardRef((props,ref) =>{
const [value,setValue]= useState('')

useEffect(()=>{
    if(props && props.defaultValue){
        setValue(props.defaultValue)
    }
},[props])

const config = useMemo(
    () => ({
    readonly: false,
    placeholder: '',
    defaultActionOnPaste: 'insert_as_html',
    defaultLineHeight: 1.5,
    enter: 'div',
   // options that we defined in above step.
    buttons: options,
    buttonsMD: options,
    buttonsSM: options,
    buttonsXS: options,
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    controls: {
    font: {
        list: {
            'Roboto Medium': 'Roboto Medium',
            'Roboto Regular': 'Roboto Regular'
        }
    }
   }
    }),
    [],
   );


    useImperativeHandle(ref,() => ({
        value: value
    }))

    return(
        <div style={{
            minHeight: 250,
            border: '1px solid var(--border-color)',
            padding: 10
        }}>
            <JoditEditor
                ref={ref}
                value={value}
                config={config}
                onChange={(htmlString) => setValue(htmlString)}
            />
        </div>
    )
})