import React, { useEffect, useRef, useState } from 'react'
import { Modal } from '../../../../Common/fModal/Modal'
import { LineHeader } from '../../../../Header/LineHeader';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec2';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';
import _ from 'lodash';
import { get, post } from '../../../../../Model/Network/Config/Axios';

const EditAdditionalCharge = ({invoiceId,id,onClose,vendorId,disabled=false,value,onRealod}) => {
    const [tds,setTds] = useState([]);
    const [tdsMode,setTdsMode] = useState('percentage');
    const [changeTds,setChangeTds] = useState(false);
    const [allTds,setAllTds] = useState([]);
    const [selectedTds,setSelectedTds] = useState(undefined);
    const Amount = useRef(),CODE=useRef();

    useEffect(()=>{
		get(`vendor/contacts/${vendorId}/details`,(e1,r1)=>{
			if(r1) {
				get(`/vendor/config/tds/all`,(e,r)=>{
					if (r) {
						if(r1.config.tdsIds.length) {
							setTds(r.tds.filter(item=>_.includes(r1.config.tdsIds,item.id)).map(val=>({
								id: val.id,
								label: val.code,
								des: val.description,
								tax: val.tax
							})))
						} else {
							setTds(r.tds.map(val=>({
								id: val.id,
								label: val.code,
								des: val.description,
								tax: val.tax
							})))
						}
						
					}
				})
			}
		})
       
    },[])

    return (
        <div>
        <Modal
            style={{
                save: {
                    padding: '12px 15px',
                    width: '100%',
                    textAlign: 'center',

                }
            }}
            show={true}
            title="Edit Additional Charge"
            des="Select Tds"
            close={onClose}
            buttonName="Update"
            onSave={() => {
                console.log('selectedTds', selectedTds)
                if(!selectedTds){
                    showToast({
                        type:"error",
                        message: "please select TDS"
                    })
                    return
                }
                post(`vendor/invoicing/${invoiceId}/invoicing.additionalCharges.update`,{'additionalCharge':id,'key':"tdsRate","value":(selectedTds.tax).toString()},(e,r)=>{
                    if(r){
                        onClose();
                        onRealod();
                    }else{
                        handleError(e);
                    }
                })
            }}
        >
            <p style={{ marginBottom: 10 }} className='floating-top-label' >Tds</p>
            <div style={{
                height: 40,
                background: 'var(--off-white)',
                border: '1px solid var(--border-color)',
                width: '100%',
                padding: 5,
                fontSize: 12,
                color: 'var(--text-color)'
            }} onClick={() => {
                if (disabled) return;
                setChangeTds(true);
            }}>
                {(!selectedTds && value) ?
                    `${value.tdsCode} (${value.tdsAmount})` : selectedTds ?
                        `${selectedTds.label} (${selectedTds.id})`
                        :
                        null}
            </div>
        </Modal>
        {
                changeTds ?
                    <Modal
                        onSave={() => {
                            if (tdsMode === 'percentage') {
                                if (!selectedTds) {
                                    showToast({
                                        type: 'error',
                                        value: 'Please enter some value'
                                    })
                                    return;
                                }
                                // onSelect({
                                //     "tdsSlab": selectedTds.tax,
                                //     "tdsCode": selectedTds.label,
                                //     "tdsDesc": selectedTds.des
                                // })
                                setChangeTds(false);
                            } else {
                                let amount = Amount.current.value;
                                let code = CODE.current.value;
                                if (!amount) {
                                    showToast({
                                        type: 'error',
                                        message: "Please enter the amount"
                                    })
                                    return;
                                }
                                if (!code) {
                                    showToast({
                                        type: 'error',
                                        message: "Please enter the remarks"
                                    })
                                    return;
                                }
                                setChangeTds(false);
                                showToast({
                                    type: 'success',
                                    message: "Field update successfully"
                                })
                            }
                        }}
                        style={{
                            save: {
                                padding: '12px 15px',
                                width: '100%',
                                textAlign: 'center',

                            }
                        }}
                        show={true}
                        title="Select Tds"
                        des="Select Tds"
                        close={() => {
                            setChangeTds(false);
                        }}
                        buttonName="Select Tds "
                    >
                        <LineHeader
                            items={
                                [
                                    {
                                        name: 'Select Percentage',
                                        active: tdsMode === 'percentage',
                                        click: () => {
                                            setTdsMode('percentage');
                                        }
                                    },

                                ]
                            }
                        />
                        <div style={{
                            marginTop: 20
                        }} />
                        {
                            tdsMode === 'percentage' ?
                                <SearchableSelect
                                    disabled={disabled}
                                    label="Select Tds"
                                    placeholder="Select Tds"
                                    onChange={val => {
                                        if (val.length > 0) {
                                            let allItems = tds.filter(item => {
                                                if (val.toLowerCase().includes(item.label)) {
                                                    return true
                                                }
                                                if (val.toLowerCase().includes(item.desc)) {
                                                    return true;
                                                }
                                                if (val.toLowerCase().includes(item.tax)) {
                                                    return true
                                                }
                                                return false;
                                            })

                                            setAllTds(allItems);
                                        } else {
                                            setTds(tds);
                                        }
                                    }}
                                    select={selectedTds => {
                                        setSelectedTds(selectedTds);
                                    }}
                                    value={selectedTds}
                                    items={allTds.length > 0 ? allTds : tds}
                                />
                                :
                                null

                        }
                    </Modal>
                    : null
            }
        </div>
    )
}

export default EditAdditionalCharge