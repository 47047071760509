import React from 'react';
import error from './error-sign.png';
import cross from './cross.png';
import Props from 'prop-types';


class WarningNotification extends React.Component {


    render() {
        return (
            <div style={{
                height: 60,
                border: '1px solid var(--red-color)',
                borderRadius: 4,
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'var(--white-color)',
                margin: '10px 0px 30px',
                ...this.props.style
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{
                        marginRight: 10
                    }}>
                        <img src={error} alt="" style={{
                            height: 35,
                            width: 35
                        }} />
                    </div>
                    <div style={{ color: 'var(--red-color)', fontSize: 15 }}>
                        {this.props.message}
                    </div>
                </div>

                <div>
                    {
                        this.props.buttonName ?
                            <div style={{
                                backgroundColor: 'var(--red-color)',
                                height: 40,
                                width: 90,
                                color: 'var(--white-color)',
                                borderRadius: 4,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 600,
                                cursor: 'pointer'
                            }} onClick={this.props.onClick}>
                                {this.props.buttonName}
                            </div> :
                            <div onClick={this.props.onClick}>
                                <img src={cross} alt="" style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} />
                            </div>

                    }
                </div>
            </div>
        )
    }
}


WarningNotification.propsTypes = {
    message: Props.string.isRequired,
    buttonName: Props.string,
    onClick: Props.func
}

export { WarningNotification }
