import React from 'react';


class Index extends React.Component {



    header = () => {
        return (
            <div style={{
                display: 'flex',
                height: 45,
                flex: 1,
                borderBottom: '1px solid var(--border-color)',
                fontWeight: 700
            }}>
                <div style={{
                    flex: 0.5,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    Items No.
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    Material Code<br/>/HSN
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    Item Description
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    Qty/Unit
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    Rate
                </div>
                <div style={{
                    flex: 0.5,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    GST Rate
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    GST Amt
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    Total
                </div>
            </div>
        )
    }

    mapItem = (item, index) => {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                minHeight: 50,
            }} key={index}>

                <div style={{
                    flex: 0.5,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {index + 1}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    {item.hsn}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    {item.name}<br/>
                    {item.notes}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign:'center'
                }}>
                    {item.qtty} / {item.unit}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.price.toFixed(2)}
                </div>
                <div style={{
                    flex: 0.5,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.taxSlab}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.tax.toFixed(2)}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                  {this.props.currency} {" "}   {item.amount.toFixed(2)}
                </div>

            </div>
        )
    }

    render() {
        let { items,vendor,billingTo } = this.props;
        let tax = 0;
        let grandAmount = 0;
        let base=0;
        let igst=0;
        let sgst=0;
        let cgst=0;
        items.forEach(val => {
            tax += val.tax;
            grandAmount += val.amount
            base+= val.base
        })
        if(vendor && vendor.gstin && billingTo && billingTo.gstin && vendor.gstin.slice(0,2)===billingTo.gstin.slice(0,2)){
            cgst = tax/2;
            sgst = tax/2;
        }else{
            igst = tax;
        }
        return (
            <div>
                {this.header()}
                {items.map(this.mapItem)}
                <div style={{
                    minHeight: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 5,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>BASE</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{base.toFixed(2)}</div>
                </div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 5,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>IGST</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{igst.toFixed(2)}</div>
                </div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 5,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>CGST</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{cgst.toFixed(2)}</div>
                </div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 5,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>SGST</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{sgst.toFixed(2)}</div>
                </div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 5,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>Total Tax</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{tax.toFixed(2)}</div>
                </div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 5,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>Grand Total</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{grandAmount.toFixed(2)}</div>
                </div>
            </div>
        )
    }
}

export default Index;
